<template>
  <section>
    <crp-tech
      :crp_tech="getCrp"
      @update-integration="addCrpTech"
    />
    <div class="right-header">Подключение гос. систем</div>
    <div class="right-body">
      <div class="cash-select">Выберите необходимую гос. систему</div>
      <div
        class="integration-container"
        style="margin-bottom: 22px"
      >
        <div
          class="dashed-border"
          style="justify-content: center"
        >
          <div
            class="no-cash"
            style="text-align: center"
          >
            Я не работаю <br />
            с гос. системами
          </div>
          <div
            class="skip-step"
            @click="skipStep"
          >
            Пропустить шаг
          </div>
        </div>
        <tour-integration-card
          v-b-toggle.crptech-sidebar
          :caption="`Национальная система цифровой маркировки и прослеживания товаров`"
          name="Честный ЗНАК"
          image="/img/integrations/crptech.svg"
        />
      </div>
      <div class="integration-container"></div>
    </div>
  </section>
</template>

<script>
  import TourIntegrationCard from '@/views/tour/components/TourIntegrationCard.vue'
  import CrpTech from '@/views/integrations/components/CrpTech.vue'

  export default {
    name: 'TourNationalSelect',
    apollo: {
      AllIntegrations: {
        query: require('../../integrations/gql/getIntegrations.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          let non_cash = []
          let cash = []
          non_cash = data?.AllIntegrations?.integrations ?? []
          cash = data?.AllIntegrations?.cash_integrations ?? []
          this.integrations = [...cash, ...non_cash]
          this.isLoading = false
        }
      }
    },
    components: { CrpTech, TourIntegrationCard },
    data() {
      return {
        integrations: [],
        isLoading: true
      }
    },
    computed: {
      getCrp() {
        return this.integrations?.find((el) => el.type === 'crptech') ?? {}
      }
    },
    methods: {
      skipStep() {
        this.$emit('next-step')
      },
      addCrpTech(crpTech) {
        this.$apollo
          .mutate({
            mutation: require('../../integrations/gql/InitCrpTech.graphql'),
            variables: {
              input: {
                items: [
                  {
                    branch: crpTech.branch,
                    entity: crpTech.entity,
                    login: crpTech.thumbprint
                  }
                ],
                type: 'crptech'
              }
            }
          })
          .then(() => {
            this.$apollo.queries.AllIntegrations.refetch()
            this.$noty.show(`Интеграция успешна`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.error(e.graphQLErrors?.[0]?.extensions?.ru ?? `Что-то пошло не так. Попробуйте еще раз`)
          })
      }
    }
  }
</script>

<style scoped></style>
