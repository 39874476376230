var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "integration"
  }, [_c('img', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "src": _vm.image,
      "width": "62",
      "height": "62"
    }
  }), _c('div', {
    staticClass: "no-cash",
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.caption) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }