var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('evotor'), _c('div', {
    staticClass: "right-header"
  }, [_vm._v("Подключение кассы")]), _c('div', {
    staticClass: "right-body"
  }, [_c('div', {
    staticClass: "cash-select"
  }, [_vm._v("Выберите вашу кассу из списка")]), _c('div', {
    staticClass: "integration-container",
    staticStyle: {
      "margin-bottom": "22px"
    }
  }, [_c('div', {
    staticClass: "dashed-border",
    staticStyle: {
      "justify-content": "space-around"
    }
  }, [_c('div', {
    staticClass: "no-cash"
  }, [_vm._v("У меня нет кассы")]), _c('b-button', {
    staticClass: "justify-content-center align-items-center",
    staticStyle: {
      "height": "42px",
      "margin-left": "32px",
      "margin-right": "32px",
      "width": "65%"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goCash
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Купить кассу")]), _c('img', {
    staticClass: "ml-2",
    attrs: {
      "src": "/img/icons/url-white.svg"
    }
  })]), _c('div', {
    staticClass: "skip-step",
    on: {
      "click": _vm.skipStep
    }
  }, [_vm._v(" Пропустить шаг ")])], 1), _c('tour-integration-card', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-right",
      modifiers: {
        "sidebar-right": true
      }
    }],
    attrs: {
      "caption": `Настройте связку и передавайте \n наименования, цены и другие \n сведения о
    товарах, блюдах и услугах`,
      "name": "Эвотор",
      "image": "/img/integrations/evotor.png"
    }
  })], 1), _c('div', {
    staticClass: "integration-container"
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }