var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-inner"
  }, [_c('img', {
    attrs: {
      "src": "/img/tour/first_step.svg"
    }
  }), _vm._m(0), _vm._m(1), _c('b-button', {
    staticClass: "p-3",
    staticStyle: {
      "font-size": "16px",
      "height": "42px",
      "margin-bottom": "10px"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goMain
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/tour/icon_play.svg"
    }
  }), _vm._v(" Начать обучающий тур ")])]), _c('div', {
    staticClass: "skip-step",
    on: {
      "click": function ($event) {
        return _vm.goMain(true);
      }
    }
  }, [_vm._v(" Разберусь самостоятельно ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticStyle: {
      "text-align": "center",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Первый шаг сделан, "), _c('br'), _vm._v(" но это лишь начало пути ")]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bottom-text"
  }, [_vm._v(" Давайте мы проведём обучающий тур по "), _c('br'), _vm._v(" разделам, чтобы вам было проще разобраться! ")]);

}]

export { render, staticRenderFns }