<template>
  <div class="integration">
    <img
      :src="image"
      width="62"
      height="62"
      style="margin-bottom: 12px"
    />
    <div
      class="no-cash"
      style="margin-bottom: 8px"
    >
      {{ name }}
    </div>
    <div>
      {{ caption }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TourIntegrationCard',
    props: {
      image: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      caption: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  .integration > * {
    user-select: none;
  }

  .integration {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }

  .no-cash {
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-bottom: 22px;
  }
</style>
