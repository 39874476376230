<template>
  <div class="container-inner">
    <img src="/img/tour/first_step.svg" />
    <h3 style="text-align: center; margin-bottom: 16px">
      Первый шаг сделан, <br />
      но это лишь начало пути
    </h3>
    <div class="bottom-text">
      Давайте мы проведём обучающий тур по <br />
      разделам, чтобы вам было проще разобраться!
    </div>
    <b-button
      style="font-size: 16px; height: 42px; margin-bottom: 10px"
      variant="primary"
      class="p-3"
      @click="goMain"
    >
      <div class="d-flex align-items-center">
        <img
          src="/img/tour/icon_play.svg"
          class="mr-2"
        />
        Начать обучающий тур
      </div>
    </b-button>
    <div
      class="skip-step"
      @click="goMain(true)"
    >
      Разберусь самостоятельно
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TourEnd',
    methods: {
      goMain(skip = false) {
        if (skip) {
          this.$router.push({ name: 'dashboard', query: { skip_tour: 'true' } })
          return
        } else this.$router.push({ name: 'dashboard' })
      }
    }
  }
</script>

<style scoped>
  .container-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
  }

  .bottom-text {
    text-align: center;
    font-size: 18px;
    color: black;
    margin-bottom: 42px;
  }
</style>
