<template>
  <section id="tour-start-page">
    <div class="root-div">
      <div class="left-div">
        <h2>Быстрый старт</h2>
        <div class="hint-text">
          Настройте основные функции <br />
          для скорейшего начала торговли
        </div>
        <div class="step-cont mb-auto">
          <div
            class="pl-4 mb-4"
            :class="step === 0 ? 'active' : ''"
          >
            <div :class="step === 0 ? 'active-text' : 'text'">Подключение кассы</div>
            <div class="primary-text">
              {{ step === 0 ? 'Не выполнено' : 'Готово' }}
            </div>
          </div>
          <div
            class="pl-4 mb-4"
            :class="step === 1 ? 'active' : ''"
          >
            <div :class="step === 1 ? 'active-text' : 'text'">Подключение гос.систем</div>
            <div class="primary-text">
              {{ national_primary() }}
            </div>
          </div>
          <div
            class="pl-4"
            :class="step === 2 ? 'active' : ''"
          >
            <div :class="step === 2 ? 'active-text' : 'text'">Обучающий тур</div>
            <div class="primary-text">Не пройден</div>
          </div>
        </div>
        <div
          class="mt-auto ml-4"
          style="margin-bottom: 32px"
        >
          <span
            class="underline-text"
            @click="skipTour"
            >Разберусь самостоятельно</span
          >
          /
          <span class="underline-text">Нужна помощь менеджера</span>
        </div>
      </div>
      <div class="right-div">
        <tour-cash-select
          v-if="step === 0"
          @next-step="step++"
        />
        <tour-national-select
          v-if="step === 1"
          @next-step="step++"
        />
        <tour-end v-if="step === 2" />
      </div>
    </div>
  </section>
</template>

<script>
  import TourCashSelect from '@/views/tour/components/TourCashSelect.vue'
  import TourNationalSelect from '@/views/tour/components/TourNationalSelect.vue'
  import TourEnd from '@/views/tour/components/TourEnd.vue'

  export default {
    name: 'StartPage',
    components: { TourEnd, TourNationalSelect, TourCashSelect },
    data() {
      return {
        step: 0
      }
    },
    methods: {
      national_primary() {
        return this.step <= 1 ? 'Не выполнено' : 'Готово'
      },
      skipTour() {
        this.$router.push({ name: 'dashboard', query: { skip: 'true' } })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .right-header {
      font-size: 28px;
      padding: 22px 21px;
      color: black;
      font-weight: 500;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .right-body {
      padding-left: 36px;
      padding-top: 32px;
      padding-right: 36px;
    }

    .cash-select {
      font-size: 18px;
      color: black;
      font-weight: 500;
      margin-bottom: 22px;
    }

    .dashed-border {
      display: flex;
      padding: 16px;
      align-items: center;
      flex: 1;
      background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: gainsboro; stroke-width: 4; stroke-dasharray: 12 12'/></svg>");
      border-radius: 4px;
      flex-direction: column;

      & > * {
        user-select: none;
      }
    }

    .main-text {
      font-size: 24px;
    }

    .no-cash {
      font-size: 18px;
      font-weight: 500;
      color: black;
      margin-bottom: 22px;
    }

    .skip-step {
      font-size: 16px;
      margin-top: 11px;
      margin-bottom: 1px;
      cursor: pointer;
      color: #00a3ff;
    }

    .integration-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 22px;
    }
  }

  #tour-start-page {
    .root-div {
      display: flex;
      flex-direction: row;
      height: 100vh;
      width: 100vw;
    }

    .left-div {
      flex: 1.05;
      align-items: center;
      padding-right: 150px;
      padding-top: 128px;
      border-right: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;
    }

    .right-div {
      flex: 0.95;
      display: flex;
      flex-direction: column;
    }

    // .right-header {
    //   font-size: 28px;
    //   padding: 22px 21px;
    //   color: black;
    //   font-weight: 500;
    //   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    // }

    // .right-body {
    //   padding-left: 36px;
    //   padding-top: 32px;
    //   padding-right: 36px;
    // }

    // .cash-select {
    //   font-size: 18px;
    //   color: black;
    //   font-weight: 500;
    //   margin-bottom: 22px;
    // }

    .dashed-border {
      // display: flex;
      // padding: 16px;
      // align-items: center;
      // flex: 1;
      // background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: gainsboro; stroke-width: 4; stroke-dasharray: 12 12'/></svg>");
      // border-radius: 4px;
      // flex-direction: column;

      & > * {
        user-select: none;
      }
    }

    .hint-text {
      font-size: 18px;
      color: #888888;
      margin-bottom: 92px;
    }

    // .main-text {
    //   font-size: 24px;
    // }

    // .no-cash {
    //   font-size: 18px;
    //   font-weight: 500;
    //   color: black;
    //   margin-bottom: 22px;
    // }

    .active-text {
      font-size: 24px;
      color: #00a3ff;
    }

    .text {
      font-size: 24px;
      color: #bcbcbc;
    }

    .primary-text {
      margin-top: 6px;
      font-size: 14px;
    }

    .step-cont {
      border-left: 2px solid #edf2ff;
    }

    .active {
      border-left: 2px solid #2caef6;
      margin-left: -2px;
    }

    .underline-text {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
      color: #888888;
    }

    // .skip-step {
    //   font-size: 16px;
    //   margin-top: 11px;
    //   margin-bottom: 1px;
    //   cursor: pointer;
    //   color: #00a3ff;
    // }

    // .integration-container {
    //   display: flex;
    //   flex-direction: row;
    //   flex-wrap: wrap;
    //   gap: 22px;
    // }
  }
</style>
