<template>
  <section>
    <evotor />
    <div class="right-header">Подключение кассы</div>
    <div class="right-body">
      <div class="cash-select">Выберите вашу кассу из списка</div>
      <div
        class="integration-container"
        style="margin-bottom: 22px"
      >
        <div
          class="dashed-border"
          style="justify-content: space-around"
        >
          <div class="no-cash">У меня нет кассы</div>
          <b-button
            style="height: 42px; margin-left: 32px; margin-right: 32px; width: 65%"
            variant="primary"
            class="justify-content-center align-items-center"
            @click="goCash"
          >
            <span style="font-size: 16px">Купить кассу</span>
            <img
              src="/img/icons/url-white.svg"
              class="ml-2"
            />
          </b-button>
          <div
            class="skip-step"
            @click="skipStep"
          >
            Пропустить шаг
          </div>
        </div>
        <tour-integration-card
          v-b-toggle.sidebar-right
          :caption="`Настройте связку и передавайте \n наименования, цены и другие \n сведения о
      товарах, блюдах и услугах`"
          name="Эвотор"
          image="/img/integrations/evotor.png"
        />
      </div>
      <div class="integration-container"></div>
    </div>
  </section>
</template>

<script>
  import TourIntegrationCard from '@/views/tour/components/TourIntegrationCard.vue'
  import Evotor from '@/views/integrations/components/Evotor.vue'

  export default {
    name: 'TourCashSelect',
    components: { Evotor, TourIntegrationCard },
    methods: {
      skipStep() {
        this.$emit('next-step')
      },
      goCash() {
        window.open('https://entersight.ru/evotor')
      }
    }
  }
</script>

<style scoped></style>
