var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "tour-start-page"
    }
  }, [_c('div', {
    staticClass: "root-div"
  }, [_c('div', {
    staticClass: "left-div"
  }, [_c('h2', [_vm._v("Быстрый старт")]), _vm._m(0), _c('div', {
    staticClass: "step-cont mb-auto"
  }, [_c('div', {
    staticClass: "pl-4 mb-4",
    class: _vm.step === 0 ? 'active' : ''
  }, [_c('div', {
    class: _vm.step === 0 ? 'active-text' : 'text'
  }, [_vm._v("Подключение кассы")]), _c('div', {
    staticClass: "primary-text"
  }, [_vm._v(" " + _vm._s(_vm.step === 0 ? 'Не выполнено' : 'Готово') + " ")])]), _c('div', {
    staticClass: "pl-4 mb-4",
    class: _vm.step === 1 ? 'active' : ''
  }, [_c('div', {
    class: _vm.step === 1 ? 'active-text' : 'text'
  }, [_vm._v("Подключение гос.систем")]), _c('div', {
    staticClass: "primary-text"
  }, [_vm._v(" " + _vm._s(_vm.national_primary()) + " ")])]), _c('div', {
    staticClass: "pl-4",
    class: _vm.step === 2 ? 'active' : ''
  }, [_c('div', {
    class: _vm.step === 2 ? 'active-text' : 'text'
  }, [_vm._v("Обучающий тур")]), _c('div', {
    staticClass: "primary-text"
  }, [_vm._v("Не пройден")])])]), _c('div', {
    staticClass: "mt-auto ml-4",
    staticStyle: {
      "margin-bottom": "32px"
    }
  }, [_c('span', {
    staticClass: "underline-text",
    on: {
      "click": _vm.skipTour
    }
  }, [_vm._v("Разберусь самостоятельно")]), _vm._v(" / "), _c('span', {
    staticClass: "underline-text"
  }, [_vm._v("Нужна помощь менеджера")])])]), _c('div', {
    staticClass: "right-div"
  }, [_vm.step === 0 ? _c('tour-cash-select', {
    on: {
      "next-step": function ($event) {
        _vm.step++;
      }
    }
  }) : _vm._e(), _vm.step === 1 ? _c('tour-national-select', {
    on: {
      "next-step": function ($event) {
        _vm.step++;
      }
    }
  }) : _vm._e(), _vm.step === 2 ? _c('tour-end') : _vm._e()], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hint-text"
  }, [_vm._v(" Настройте основные функции "), _c('br'), _vm._v(" для скорейшего начала торговли ")]);

}]

export { render, staticRenderFns }