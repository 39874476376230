var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('crp-tech', {
    attrs: {
      "crp_tech": _vm.getCrp
    },
    on: {
      "update-integration": _vm.addCrpTech
    }
  }), _c('div', {
    staticClass: "right-header"
  }, [_vm._v("Подключение гос. систем")]), _c('div', {
    staticClass: "right-body"
  }, [_c('div', {
    staticClass: "cash-select"
  }, [_vm._v("Выберите необходимую гос. систему")]), _c('div', {
    staticClass: "integration-container",
    staticStyle: {
      "margin-bottom": "22px"
    }
  }, [_c('div', {
    staticClass: "dashed-border",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "skip-step",
    on: {
      "click": _vm.skipStep
    }
  }, [_vm._v(" Пропустить шаг ")])]), _c('tour-integration-card', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.crptech-sidebar",
      modifiers: {
        "crptech-sidebar": true
      }
    }],
    attrs: {
      "caption": `Национальная система цифровой маркировки и прослеживания товаров`,
      "name": "Честный ЗНАК",
      "image": "/img/integrations/crptech.svg"
    }
  })], 1), _c('div', {
    staticClass: "integration-container"
  })])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-cash",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Я не работаю "), _c('br'), _vm._v(" с гос. системами ")]);

}]

export { render, staticRenderFns }